export default {
  BUTTON_PRIMARY_CLASS: 'cm_button button button-primary',
  BUTTON_SECONDARY_CLASS: 'cm_button button button-secondary',
  SEARCH_BOX_BUTTON_CLASS: 'cm_button button button-primary',

  PRODUCT_REPEATER_CLASS: 'product-grid grid grid-2-col grid-3-col-tablet grid-3-col-desktop',
  RELATED_PRODUCT_LIST_CLASS: 'product-grid grid grid-2-col grid-3-col-tablet grid-4-col-desktop',

  VERIFY_FITMENT_TITLE: 'Verify vehicle fitment',
  VERIFY_FITMENT_COMPATIBLE_PARTS: 'Click here for products that fit your vehicle',
};
